var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.egressProfileMatterDescriptor)?_c('div',[(
      (_vm.matterMiddleDescriptor && _vm.matterMiddleDescriptor.is_visible) ||
      _vm.allows_crud
    )?_c('b-table-simple',{staticClass:"mt-2",attrs:{"bordered":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.egressProfileMatterDescriptor.title)+" ")]),(
                _vm.allows_crud &&
                _vm.isClosedOldMatterPartial(
                  _vm.egressProfileMatterDescriptor.internal_use_id
                )
              )?[(_vm.matterMiddleDescriptor)?_c('span',[_c('b-button',{staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.showDescriptor(_vm.matterMiddleDescriptor)}}},[(_vm.matterMiddleDescriptor.is_visible)?[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Mostrar`),expression:"`Mostrar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})]:[_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Ocultar`),expression:"`Ocultar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-actions",attrs:{"icon":"eye-slash","scale":"1"}})]],2)],1):_vm._e(),_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Editar`),expression:"`Editar`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1 p-0",on:{"click":function($event){return _vm.slotUpdatedTitle(_vm.egressProfileMatterDescriptor)}}}),(_vm.show_deleted_button && _vm.matterMiddleDescriptor)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(`Limpiar Contenido`),expression:"`Limpiar Contenido`",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1 mr-1 p-0",attrs:{"variant":"none"},on:{"click":function($event){return _vm.deleteDescriptors(_vm.matterMiddleDescriptor)}}},[_c('b-icon-x-square')],1):(
                  _vm.institution &&
                  _vm.institution.internal_use_id == 'duoc_uc' &&
                  _vm.egressProfileMatterDescriptor &&
                  _vm.egressProfileMatterDescriptor.internal_use_id != null
                )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                  `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`
                ),expression:"\n                  `Este objeto es necesarios para el funcionamiento de la maleta didáctica.`\n                ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('button-delete',{attrs:{"disabled":""}})],1):_vm._e()]:_vm._e()],2)])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[(_vm.matterMiddleDescriptor)?[_c('div',{staticClass:"descriptor-body pt-2 pb-3 rich-text-content",domProps:{"innerHTML":_vm._s(_vm.matterMiddleDescriptor.description)}}),(
                _vm.allows_crud &&
                _vm.isClosedOldMatterPartial(
                  _vm.egressProfileMatterDescriptor.internal_use_id
                )
              )?_c('div',[_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `${_vm.egressProfileMatterDescriptor.title}`
                ),expression:"\n                  `${egressProfileMatterDescriptor.title}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-center",on:{"click":function($event){return _vm.$bvModal.show(
                    `${_vm.egressProfileMatterDescriptor.id}edit-description-modal-${_vm.matterMiddleDescriptor.id}`
                  )}}}),_c('b-modal',{attrs:{"id":`${_vm.egressProfileMatterDescriptor.id}edit-description-modal-${_vm.matterMiddleDescriptor.id}`,"title":`${_vm.egressProfileMatterDescriptor.title}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":_vm.matterMiddleDescriptor,"Text":_vm.matterMiddleDescriptor.description,"permit_blank":true},on:{"save":_vm.updateDescription,"close":function($event){return _vm.$bvModal.hide(
                      `${_vm.egressProfileMatterDescriptor.id}edit-description-modal-${_vm.matterMiddleDescriptor.id}`
                    )}}})],1)],1):_vm._e()]:[(
                _vm.allows_crud &&
                _vm.isClosedOldMatterPartial(
                  _vm.egressProfileMatterDescriptor.internal_use_id
                )
              )?_c('div',[_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `${_vm.egressProfileMatterDescriptor.title}`
                ),expression:"\n                  `${egressProfileMatterDescriptor.title}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-center",on:{"click":function($event){return _vm.$bvModal.show(
                    `${_vm.egressProfileMatterDescriptor.id}add-description-modal-${_vm.egressProfileMatterDescriptor.id}`
                  )}}}),_c('b-modal',{attrs:{"id":`${_vm.egressProfileMatterDescriptor.id}add-description-modal-${_vm.egressProfileMatterDescriptor.id}`,"title":`${_vm.egressProfileMatterDescriptor.title}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Type":_vm.egressProfileMatterDescriptor,"permit_blank":true},on:{"save":_vm.createDescription,"close":function($event){return _vm.$bvModal.hide(
                      `${_vm.egressProfileMatterDescriptor.id}add-description-modal-${_vm.egressProfileMatterDescriptor.id}`
                    )}}})],1)],1):_vm._e()]],2)],1)],1)],1):_vm._e(),_c('b-modal',{attrs:{"id":`${_vm.egressProfileMatterDescriptor.id}-title-modal`,"title":`Editar título`,"size":"lg","hide-footer":""},on:{"hide":_vm.resetTitleModal}},[_c('b-form-group',{attrs:{"label-cols":"0","label-cols-sm":"2","label-for":"descriptor-title-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"descriptor-title-label",attrs:{"id":"descriptor-title-input"}},[_vm._v("Título")])]},proxy:true}],null,false,3002115402)},[_c('div',[_c('div',[_c('b-form-input',{attrs:{"id":"descriptor-title-input","aria-describedby":"input-title-live-feedback","size":"sm"},model:{value:(_vm.title_proccess.title),callback:function ($$v) {_vm.$set(_vm.title_proccess, "title", $$v)},expression:"title_proccess.title"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-title-live-feedback"}},[_c('div',[_vm._v("Este campo debe tener al menos 1 caracteres.")])])],1),_c('div',{staticClass:"mt-2 d-flex w-100"},[[(_vm.title_proccess.internal_use_id == null)?[_c('b-form-checkbox',{staticClass:"descriptor-title-check w-100",model:{value:(_vm.title_proccess.is_default),callback:function ($$v) {_vm.$set(_vm.title_proccess, "is_default", $$v)},expression:"title_proccess.is_default"}},[_vm._v("Predeterminado "),_c('InfoTooltip',{staticClass:"selection-info ml-0",attrs:{"tooltip_text":`${_vm.Text_info_tooltip}`}})],1)]:[_c('b-form-checkbox',{staticClass:"descriptor-title-check w-100",attrs:{"checked":_vm.title_proccess.is_default,"disabled":true}},[_vm._v("Predeterminado "),_c('InfoTooltip',{staticClass:"selection-info ml-0",attrs:{"tooltip_text":`${_vm.Text_info_tooltip}`}})],1)]]],2)])]),_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.save(_vm.title_proccess)}}},[_vm._v(" Guardar Título ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }